<template>
<v-container grid-list-xl fluid>
    <v-layout row wrap>
        <v-flex lg12 pb-0 v-if="orderbutton" class="hidden-sm-and-down">
            <div class="row group-box">
                <div class="col-md-2">
                    <a href="javascript: void(0)"
                        @click="onclickStatus({ value: -1, name: 'Tất cả', color: '#fff' })"
                        :class="{ 'active-status': selectedStatus === -1 }"
                        style="border-left-color: #bdc3d1"
                        class="btn btn-default btn-sm btn-block btn-status">
                        Tất cả<br/>({{getTotalOrder(-1, false)}})
                    </a>
                </div>
                <div class="col-md-8">
                    <div class="row pt-3">
                        <div class="col-md-3 pa-0 pl-2" v-for="status of listStatus" v-bind:key="status.value">
                            <a v-if="status.value != -2 && status.value != 10" href="javascript: void(0)"
                                :style="{ 'border-left-color': status.color }"
                                @click="onclickStatus(status)"
                                :class="{ 'active-status': selectedStatus === status.value }"
                                class="btn btn-default btn-sm btn-block btn-order-status">
                                {{ status.name }}
                                <span class="total">{{getTotalOrder(status.value)}}</span>
                            </a>
                        </div>
                    </div>
                </div>
                <div class="col-md-2 pl-4" v-if="orderType == 1">
                    <a  href="javascript: void(0)"
                        @click="onclickStatus({ value: 10, name: 'Hoàn thành', color: 'blue' })"
                        :class="{ 'active-status': selectedStatus === 10 }"
                        style="border-left-color: #7a8498"
                        class="btn btn-default btn-sm btn-block btn-status">
                        Hoàn thành<br/>({{getTotalOrder(10, false)}})
                    </a>
                </div>
            </div>        
        </v-flex>
        <v-flex lg12 pt-0>
            <v-app-bar flat color="white">                
                <v-text-field flat solo prepend-icon="search" :placeholder="isChinessLanguage ? '搜查' : 'Tìm kiếm'" v-model="quickSearchTerm" hide-details v-on:keyup.enter="quickSearchData" v-shortkey.focus="['alt', 'i']"></v-text-field>
                <template v-for="(cond, index) in conditions">
                    <v-menu v-model="cond.menu" :key="index" :close-on-content-click="false" :nudge-width="200" offset-x v-if="refreshFilter">
                        <template v-slot:activator="{ on }">
                            <v-chip close class="ml-1" v-on="on" @click:close="removeFilter(cond)">{{conditionDisplay(cond)}}</v-chip>
                        </template>
                        <v-card>
                            <v-widget :title="cond.name">
                                <div slot="widget-content">
                                    <v-select v-model="cond.selectedCondition" :items="cond.conditions" item-value="value" item-text="name"></v-select>
                                    <v-text-field v-model="cond.value" v-if="cond.dataType === dataTypes.String" :autofocus="true" @keyup.enter="advanceFilterData(cond)"></v-text-field>
                                    <h-currency-input v-model="cond.value" v-if="cond.dataType === dataTypes.Number" :decimal="cond.decimal" :autofocus="true" @press_enter="advanceFilterData(cond)"></h-currency-input>
                                    <v-select :chips="true" :multiple="true" :small-chips="true" :items="cond.listOfValue" v-model="cond.value" item-text="name" item-value="value" v-if="cond.dataType === dataTypes.Enum"></v-select>
                                    <datepicker v-model="cond.value" v-if="cond.dataType === dataTypes.Date" format="dd/MM/yyyy" :autofocus="true"></datepicker>
                                    <v-flex class="align-end">
                                        <v-btn @click="advanceFilterData(cond)">OK</v-btn>
                                        <v-btn @click="closePopup(cond)" class="ml-1">Hủy</v-btn>
                                    </v-flex>
                                </div>
                            </v-widget>
                        </v-card>
                    </v-menu>
                </template>
                <v-menu :close-on-content-click="false" v-model="filterMenu" :nudge-width="200" offset-x offset-y v-if="advanceFilterable">
                    <template v-slot:activator="{ on }">
                        <v-btn icon v-on="on">
                            <v-icon>filter_list</v-icon>
                        </v-btn>
                    </template>
                    <v-list>
                        <template v-for="(item, index) in headers">
                            <v-list-item :key="index" v-if="item.filterable" @click="enterCondition(item.value)">
                                <v-list-item-title>{{ item.text }}</v-list-item-title>
                            </v-list-item>
                        </template>
                    </v-list>
                </v-menu>
            </v-app-bar>
            <v-divider class="ma-0"></v-divider>
        </v-flex>
    </v-layout>
</v-container>
</template>

<script>
import configs from "@/configs";
import _ from "lodash";
import moment from "moment";
import VWidget from "@/components/VWidget";
import Datepicker from "vuejs-datepicker";
import {
    display_date
} from "@/commons/formater";
import {
    currency
} from "@/commons/currency";

export default {
    name: "filtering",
    filters: {},
    components: {
        VWidget,
        Datepicker
    },
    props: {
        headers: Array,
        viewName: String,
        orderbutton: Boolean,
        orderSummary: Object,
        listStatus: Array,
        orderType: Number
    },
    data() {
        return {
            show: true,
            selectedStatus: -1,
            filterMenu: false,
            selectedConditionValue: "",
            selected: [],
            comparions: configs.LIST_COMPARISON,
            dataTypes: configs.DATA_TYPE,
            quickSearchTerm: "",
            refreshFilter: true,
        };
    },
    computed: {
        selectedCondition() {
            let conditions = [];
            let column = _.find(this.headers, {
                value: this.selectedConditionValue
            });
            if (column) {
                conditions = _.filter(this.comparions, {
                    dataType: column.dataType
                });
            }
            return conditions;
        },
        conditions: {
            get() {
                let filters = this.$store.state.commons.filters;
                return !_.isEmpty(filters) && !_.isEmpty(filters[this.viewName]) ?
                    filters[this.viewName] : [];
            },
            set(value) {
                let filters = this.$store.state.commons.filters || {};
                filters[this.viewName] = value;
                this.$store.commit("commons/setFilters", {
                    filter: filters
                });
            }
        },
        filterConditions: {
            get() {
                let filterConditions = this.$store.state.commons.filterConditions;
                return !_.isEmpty(filterConditions) ?
                    filterConditions[this.viewName] : [];
            },
            set(value) {
                let filterConditions = this.$store.state.commons.filterConditions || {};
                filterConditions[this.viewName] = value;
                this.$store.commit("commons/setFilterConditions", {
                    filter: filterConditions
                });
            }
        },
        advanceFilterable() {
            return _.find(this.headers, {'filterable': true}) != null;
        },
        isChinessLanguage() {
            return (this.$store.state.authentication.user.roletype & configs.ROLE_TYPE.StoreTQ) == configs.ROLE_TYPE.StoreTQ && this.$store.state.authentication.user.ischinesslanguage;
        },
    },
    watch: {
        filterConditions() {
           this.getLastStatus();
        },
        conditions() {
           this.getLastStatus();
        }
    },
    mounted() {
        this.conditions = []
        this.filterConditions = []
        this.getLastStatus();
    },
    methods: {
        getTotalOrder(status, format = true) {
            var total = 0;
            if(!this.orderSummary) {
                return 0;
            }
            switch (status) {
                case 0:
                    total = this.orderSummary.TotalStatus0;
                    break;
                case 1:
                    total = this.orderSummary.TotalStatus1;
                    break;
                case 2:
                    total = this.orderSummary.TotalStatus2;
                    break;
                case 3:
                    total = this.orderSummary.TotalStatus3;
                    break;
                case 4:
                    total = this.orderSummary.TotalStatus4;
                    break;
                case 5:
                    total = this.orderSummary.TotalStatus5;
                    break;
                case 7:
                    total = this.orderSummary.TotalStatus7;
                    break;
                case 8:
                    total = this.orderSummary.TotalStatus8;
                    break;
                case 9:
                    total = this.orderSummary.TotalStatus9;
                    break;
                case 10:
                    total = this.orderSummary.TotalStatus10;
                    break;
                 case 11:
                    total = this.orderSummary.TotalStatus11;
                    break;
                case 12:
                    total = this.orderSummary.TotalStatus12;
                    break;
                case 13:
                    total = this.orderSummary.TotalStatus13;
                    break;
                case 14:
                    total = this.orderSummary.TotalStatus14;
                    break;
                case 15:
                    total = this.orderSummary.TotalStatus15;
                    break;
                case 16:
                    total = this.orderSummary.TotalStatus16;
                    break;
                default:
                    total = this.orderSummary.TotalStatusAll;
                    break;
            }

            if(total < 999 || !format) {
                return total.toLocaleString('en-US');
            }
            return (Math.floor(total / 1000, 0)).toLocaleString('en-US') + 'K';
        },
        enterCondition(item) {
            let column = _.find(this.headers, {
                value: item
            });
            let condition = {};
            if (column) {
                _.each(column.values, itm => _.set(itm, 'disabled', false));
                let condition_filters = _.filter(this.comparions, {
                    dataType: column.dataType
                });
                condition = {
                    column: item,
                    name: column.text,
                    value: column.dataType === configs.DATA_TYPE['Number'] ? 0 : "",
                    menu: false,
                    decimal: column.decimal || 0,
                    dataType: column.dataType,
                    listOfValue: column.values,
                    conditions: condition_filters,
                    selectedCondition: condition_filters[0].value
                };
                this.conditions = _.concat(this.conditions, condition);
            }
            this.selected = [];
            this.filterMenu = false;
            this.$nextTick(() => {condition.menu = true;});
        },
        conditionDisplay(cond) {
            const condition = _.find(this.comparions, {
                value: cond.selectedCondition
            });
            let displayValue = "";
            if (cond.value !== null && cond.value !== undefined && cond.value !== "") {
                switch (cond.dataType) {
                    case this.dataTypes.Date:
                        displayValue = display_date(cond.value, "DD/MM/YYYY");
                        break;
                    case this.dataTypes.Number:
                        displayValue = currency(cond.value, "", cond.decimal || 0);
                        break;
                    case this.dataTypes.Enum:
                        let listVals = _.split(cond.value, ",");
                        let displayVals = [];
                        for (let i = 0; i < cond.listOfValue.length; i++) {
                            const element = cond.listOfValue[i];
                            if (listVals.includes(element.value.toString())) {
                                displayVals.push(element.name);
                            }
                        }
                        displayValue = displayVals.join(", ");
                        break;
                    default:
                        displayValue = cond.value;
                        break;
                }
            }
            return (
                cond.name +
                " " +
                (condition ? condition.display.replace("{0}", displayValue) : "")
            );
        },
        removeFilter(condition) {
            _.remove(this.conditions, {
                column: condition.column
            });
            this.advanceFilterData();
            this.refreshFilterChips();
        },
        refreshFilterChips() {
            this.refreshFilter = false;
            setTimeout(() => {
                this.refreshFilter = true;
            }, 100);
        },
        getLastStatus() {
            const items = _.filter(this.conditions, {'column': 'Status'});
            const status = items.length == 1 ? items[0] : null;
            if(status && status.value.length > 0) {
                this.selectedStatus = status.value[0]
            }
            else {
                this.selectedStatus = -1;
            }
        },
        onclickStatus(status) {
            this.selectedStatus = status.value;
            let column = _.find(this.headers, {
                value: 'Status'
            });
            let condition = {};
            if (column && this.selectedStatus !== -1) {
                _.each(column.values, itm => _.set(itm, 'disabled', false));
                let condition_filters = _.filter(this.comparions, {
                    dataType: column.dataType
                });
                condition = {
                    column: 'Status',
                    name: column.text,
                    value: [this.selectedStatus],
                    menu: false,
                    decimal: column.decimal || 0,
                    dataType: column.dataType,
                    listOfValue: column.values,
                    conditions: condition_filters,
                    selectedCondition: condition_filters[0].value
                };
                this.conditions = [condition];
            }
            else {
                this.conditions = [];
            }
            this.selected = [];
            this.filterMenu = false;
            this.quickSearchData(false);
            this.advanceFilterData();
        },
        quickSearchData(isFilter = true) {
            let quickConditions = [];
            if(this.quickSearchTerm != "") {
                this.quickSearchTerm = this.quickSearchTerm.trim();
                for (let i = 0; i < this.headers.length; i++) {
                    const column = this.headers[i];
                    if ((column.dataType === configs.DATA_TYPE.String && column.quickSearch) || column.value === 'ID' || column.value === 'OrderID') {
                        let condition = {
                            column: column.value,
                            value: this.quickSearchTerm,
                            condition: "contain"
                        };
                        quickConditions = _.concat(quickConditions, condition);
                    }
                }
            }
            this.$emit("quickFilter", quickConditions, isFilter);
        },
        advanceFilterData(cond) {
            this.closePopup(cond);
            const filter = _.map(this.conditions, obj => {
                obj.menu = false;
                return {
                    column: obj.column,
                    value: obj.value,
                    condition: obj.selectedCondition
                };
            });
            this.filterConditions = filter;
            this.$emit("applyFilter", filter);
            this.refreshFilterChips();
        },
        closePopup(cond) {
            if (cond) {
                cond.menu = false;
            }
            this.refreshFilterChips();
        }
    }
};
</script>

<style  lang="scss" scoped>
.btn {
    display: inline-block;
    margin-bottom: 0;
    font-weight: normal;
    text-align: center;
    vertical-align: middle;
    touch-action: manipulation;
    cursor: pointer;
    background-image: none;
    border: 1px solid transparent;
    white-space: nowrap;
    padding: 10px 12px;
    font-size: 12px;
    line-height: 1.42857143;
    border-radius: 2px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}
.btn-default {
    color: #000000;
    background-color: #ffffff;
    border-color: #bdc3d1;
    text-decoration: none;
}
.btn-status {
    height: 87px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    border-left: 3px solid;
}
.btn-sm, .btn-group-sm > .btn {
    padding: 5px 8px;
    font-size: 11px;
    line-height: 1.5;
    border-radius: 2px;
}
.btn-block {
    width: 100%;
}
.btn-default:hover {
    color: #696c74;
    background-color: #ededed;
    border-color: #9fa8bc;
}
.btn-sm, .btn-group-sm > .btn {
    padding: 5px 8px 4px;
}
.btn-order-status {
    border-left: 3px solid;
    margin-bottom: 3px;
    span {
        float: right;
        text-align: right;
        border-left: 1px solid;
        border-color: #bdc3d1;
        width: 15%;
        height: 100%;
    }
}
.btn-m-status {
    border-left: 3px solid;
}
.group-box-mb {
    display: none;
    .group-status {
        width: 100%;
        background: #fff;
        border: 1px solid #d8dce3;
        box-shadow: none;
        .btn-toggle {
            width: 100%;
            color: #696c74;
        }
        .dropdown-menu {
            width: 100%;
        }
    }
}

.active-status {
    color: #ffffff;
    background-color: #3b4354;
    border-color: #3b4354;
    a:hover {
        color: #ffffff;
        background-color: #3b4354;
        border-color: #3b4354;
    }
    a {
        color: #ffffff;
    }
}
.divider {
    margin: 0px 0px;
}
.filter-text-box {
    margin-top: 15px;
    margin-bottom: 0px;
    .form-control {
        border: 0px;
        border-bottom: 1px solid #bdc3d1;
        border-bottom-left-radius: 0px;
        border-bottom-right-radius: 0px;
    }
}

@media (max-width: 992px) {
    .group-box-mb {
        display: block;
    }
    .group-box {
        display: none;
    }
}
</style>
